/* eslint-disable */
import React from 'react';
import { PageProps } from 'gatsby';

import '@/scss/app.scss';

import SEO from '@/components/SEO';
import Ct from '@/classes/Ct';
import { AppContextProvider } from '@/context';
import AppHeader from '@/components/AppHeader';

const About: React.FC<PageProps> = ({ location }) => {
  return (
    <div className="master">
      <SEO title="About | Fredrik Kallevik" pathname={location.pathname} />
      <AppHeader pathname={location.pathname} />
      <main role="main" className="ws-1">
        <AppContextProvider>
          <dialog className="wdw" open>
            <header className="wdw-head py-2">
              <div>
                <h2 className="h-capitalized mb-0">About</h2>
              </div>
              <div>
                <nav className="wdw-nav"></nav>
              </div>
            </header>
            <div className="wdw-body">
              <dialog className="subwdw">
                <header className="subwdw-head d-flex"></header>
                <div className="subwdw-body">
                  <article className="article">
                    <h3>Tl;dr</h3>
                    <p>
                      {
                        'I care about the craft and strive to be the best. I am currently self-employed and open to consulting work so if you need help on your software project, get in touch!'
                      }
                    </p>
                    {Ct.getE()}
                    {Ct.getP()}
                    <h3>Why this website</h3>
                    <p>
                      {
                        'I wanted to showcase my résumé and something I have made. Most of the work I have done, have been for clients of my employer, such that I cannot showcase it. My hope is that this website encodes some of the qualities that pertains to my work - and maybe a little personality to help you get an idea of me :)'
                      }
                    </p>
                    <p>
                      {
                        'I must confess it is hard making something supposed to be representative of me or my work. The tendency for a serious scope creep lingers closely, so I have been forced to reel in my ambition in order to be able to ship within my own deadline. So, I plead your forgiveness that it is not mind-blowing, while I take comfort, in the logic of Occam’s Razor, that "the simple solution is usually the right one."'
                      }
                    </p>
                    <h3>My professional career started in August 2015</h3>
                    <p>
                      {
                        'And up until 2020 I was a full-time employee and co-owner (since 2018) of a small company, of five people, called iDrift Web. We worked as contractors and consultants on web and app development projects.'
                      }
                    </p>
                    <h3>Working for a small company</h3>
                    <p>
                      {
                        'With a flat hierarchy, I acquired a breadth of competance through being involved with most of the customer facing operations of the business. Throughout my career I been in regular contact with customers, involved with sales, writing offers and proposals, managing, planning and estimating projects. I am grateful that I have been able to practice multiple disciplines, in addition to my choice of dedication, programming.'
                      }
                    </p>
                    <h3>As a developer and programmer</h3>
                    <p>
                      {
                        'I feel I have cemented a good foundation from which I look forward to build upon. The infamous imposter syndrome has scurried away as a consequence of experience, and I have so far clocked in just over the mythic 10 000 hours of professional work. '
                      }
                    </p>
                    <p>
                      {
                        'I would describe myself as a full-stack developer, as I have worked both the front end and back end of most of the projects I have been involved with. The majority of my experience has revolved around PHP, JavaScript, HTML and SCSS. In my free-time I have dabbled in other programming languages, most prominently Golang, in which the code for my (our) '
                      }
                      <a
                        href="https://github.com/s111/gamesystem"
                        target="_blank"
                        rel="noreferrer"
                      >
                        bachelor thesis
                      </a>
                      {' was written, all those years ago.'}
                    </p>
                    <p>
                      {
                        'To a large extent I have been a solo-developer on projects. These projects include 30+ WordPress websites (built from scratch), 7+ Laravel web applications, all ranging from 100 to 1000+ hours. In addition to these I have also collaborated with teams from 2-5 people on bigger projects.'
                      }
                    </p>
                    <p>
                      {
                        'I have played a central part in the development of a multi-tenant white-labeled mobile app, written in React Native, and a webshop, built from scratch, with Laravel.'
                      }
                    </p>
                    <p>
                      {
                        'I have become familiar with the Bash command line, in both Linux and OSX, over the years. Git has been a daily companion, almost since day 1 of programming. I’ve been part responsible for the management of web servers running Ubuntu, mainly operating Nginx, PHP, Elastic Search, Redis, MongoDB and MySQL. I have also gained some DevOps experience through the setup and usage of CI/CD-pipelines in GitLab, and automated management/configuration of infrastructure with Saltstack Salt.'
                      }
                    </p>
                    <h3>Aesthetics and design</h3>
                    <p>
                      {
                        'It has always been a passion for me, and it is is clear to me that it has yielded some synergy, especially with regards to my front end work. I have a proclivity for beauty, and I cultivate it. However, I would point out that I am not limited to the visual, but also have a genuine interest in both the technical and abstract. Design and programming are not mutually exclusive. Both boil down to the ability to communicate ideas, whether through visuals, data structures, code or other means.'
                      }
                    </p>
                    <blockquote>
                      {
                        '"Design is the art of arranging code to work today, and be changeable forever."'
                      }
                      <span>- Sandi Metz</span>
                    </blockquote>
                    <h3>Craftmanship and Creativity</h3>
                    <p>
                      {
                        ' I feel rewarded when I am able to excercise creativity. Software development is, in my opinion, a creative discipline. My passion for being a developer seems to be growing as a function of my competance in the field. I enjoy being on the path of its mastery while striving to build things that are '
                      }
                      <em>good</em> and <em>useful</em>. To do work that we
                      developers, ourselves, dare to declare as good{' '}
                      <em>craftsmanship</em>.
                    </p>

                    <h3>Available for hire</h3>
                    <p>
                      {
                        'In 2020 I decided to move from a being a full-time employee and take on the exciting venture of starting my own software company - Dokkodo. The core of my work is consulting, so if you are looking for help on a software project, look no further!'
                      }
                    </p>
                    <h3>Get in touch</h3>
                    {Ct.getE()}
                    {Ct.getP()}
                  </article>
                </div>
              </dialog>
            </div>
          </dialog>
        </AppContextProvider>
      </main>
    </div>
  );
};

export default About;
